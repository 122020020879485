import SectionWrapper, {
  ButtonGroup,
  ContentSection,
} from "./beautyContent.style"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import React from "react"
import Text from "../../components/Text"

const BeautyContent = () => {
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={200}>
          <ContentSection>
            <div className="content">
              <Text content="At Elegant Beauty Clinic we have an incredibly talented beauty department offering the below celebrity favourite treatments:" />
              <ul>
                {/* <li>Eyelash Extensions</li> */}
                <li>Lash Lift & Tint</li>
                <li>Eyebrow Shaping</li>
                <li>Brow Lamination</li>
                <li>Alumier Facials</li>
                <li>Alumier Facial Peels</li>
                <li>ZO Facials</li>
                <li>Gel Nails</li>
                <li>Skin Scanning with our state of the art machine</li>
                <li>Dermaplaning</li>
                <li>Hydrafacial</li>
                {/* <li>Hydrafacial</li> */}
                <li>Gel Nails</li>
                <li>Pedicures</li>
                {/* <li>Kart Pedicures</li> */}
                {/* <li>Cluster Lashes</li> */}
                {/* <li>Hot Stone Massages</li>
                <li>Swedish Massage</li>
                <li>Indian Head Massage</li>
                <li>Intimate Waxing</li> */}
              </ul>
              <Text content="Book in for one of our beauty treatments in our award winning clinic." />
              <Fade up delay={200}>
                <ButtonGroup>
                  <a
                    href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button title="Book a treatment" />
                  </a>
                </ButtonGroup>
              </Fade>
            </div>
          </ContentSection>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default BeautyContent
